<template>
  <div class="px-2 d-flex justify-content-between align-items-center w-100 h-100">
    <div
      class="brand-logo mr-1 cursor-pointer"
      @click="onLogoClick"
    >
      <b-img
        id="logo"
        :src="echopeLogo"
        alt="logo"
        fluid
        class="rounded"
      />
    </div>

    <div>
      <div
        class="d-flex cursor-pointer"
        style="gap: 20px;"
        @click="onShowCartSidebar"
      >
        <div class="position-relative">
          <b-icon
            class="h2"
            icon="cart"
            style="color: var(--theme-color);"
          />

          <b-badge
            v-if="getTotalItemsQuantity > 0"
            class="position-absolute"
            style="top: -10px; right: -15px;"
            variant="danger"
          >
            {{ getTotalItemsQuantity }}
          </b-badge>
        </div>
        <p class="mb-0 h2 text-light">
          {{ getTotalSale | currency }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { app } from '@/mixins'
import { BBadge, BIcon, BImg } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import echopeLogo from '@/assets/images/logo/echope.png'
import { getInitialAtmCatalogFilters } from '@/store/pages/pdv/atm/atm-catalog'

export default {
  components: {
    BImg,
    BIcon,
    BBadge,
  },

  mixins: [app],

  data() {
    return {
      echopeLogo
    }
  },

  computed: {
    ...mapGetters('pages/pdv/payBox', [
      'getItems',
      'getTotalSale',
      'getTotalItemsQuantity'
    ]),
  },

  methods: {
    ...mapActions('pages/pdv/atm/status', {
      stSetIsCartOpen: 'setIsCartOpen',
    }),
    ...mapActions('pages/pdv/atm/catalog', {
      stSetFiltersAndSearch: 'setFiltersAndSearch',
    }),

    onLogoClick() {
      this.stSetFiltersAndSearch(getInitialAtmCatalogFilters())
    },

    onShowCartSidebar() {
      this.stSetIsCartOpen(true)
    },
  }
}
</script>

<style lang="scss" scoped>
.brand-logo>img {
  box-sizing: border-box;
  width: 200px;
}
</style>
