var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.catalog.length > 0
        ? _c(
            "b-row",
            { staticClass: "itens-container" },
            [
              _vm._l(_vm.catalog, function (item) {
                return _c(
                  "b-col",
                  {
                    key: (item.kit ? "kit" : "product") + "-" + item.id,
                    staticClass: "mb-2",
                    attrs: { md: "3" },
                  },
                  [_c("item-card", { attrs: { item: item } })],
                  1
                )
              }),
              _vm.catalogLoading
                ? _c(
                    "b-col",
                    { staticClass: "p-3 text-center", attrs: { md: "12" } },
                    [_vm._v(" Carregando mais produtos... ")]
                  )
                : _vm._e(),
              _vm.paging.allRowsLoaded
                ? _c(
                    "b-col",
                    { staticClass: "p-3 text-center", attrs: { md: "12" } },
                    [_vm._v(" Todos os nossos produtos já foram carregados ")]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm.catalogLoading
        ? _c(
            "div",
            { staticClass: "d-flex align-items-center justify-content-center" },
            [_c("p", [_vm._v("Buscando produtos...")])]
          )
        : _c(
            "div",
            { staticClass: "d-flex align-items-center justify-content-center" },
            [_c("p", [_vm._v("Nenhum produto encontrado")])]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }