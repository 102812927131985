var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-2" },
    [
      _c(
        "b-row",
        {
          staticClass:
            "mb-1 py-1 position-sticky bg-light d-flex justify-content-between",
          staticStyle: { top: "0px", "z-index": "1" },
        },
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c(
              "div",
              { staticClass: "group-container" },
              [
                _vm.getGroups.length > 0
                  ? _vm._l(_vm.getGroups, function (group, index) {
                      return _c("group-item", {
                        key: group.id + "_" + index,
                        attrs: {
                          group: group,
                          selected: _vm.filters.groupId === group.id,
                        },
                        on: { click: _vm.onSelectGroup },
                      })
                    })
                  : [_vm._v(" Nenhum grupo encontrado para filtrar ")],
              ],
              2
            ),
          ]),
          _c(
            "b-col",
            { staticClass: "mt-2", attrs: { md: "12" } },
            [
              _c("FormulateInput", {
                attrs: {
                  type: "osk-text",
                  placeholder: "Pesquisar Produto",
                  name: "productSearch",
                  "osk-type": "mobile",
                },
                on: { input: _vm.onSearchProduct },
                model: {
                  value: _vm.productSearch,
                  callback: function ($$v) {
                    _vm.productSearch = $$v
                  },
                  expression: "productSearch",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("atm-catalog-product-list"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }