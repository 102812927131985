var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: { show: _vm.showSidebar, title: _vm.$t("Carrinho"), width: "700px" },
    on: { hidden: _vm.onHide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "cart-container" },
              [
                _vm.getItems.length > 0
                  ? _c(
                      "b-row",
                      { staticClass: "cart-itens-container" },
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("e-pay-box-sale-item-table", {
                              staticClass: "bordered",
                              attrs: {
                                "table-items": _vm.getItems,
                                "show-img": "",
                                "show-number": false,
                                "show-table-footer": "",
                                "item-read-only": "",
                                "prices-to-right": "",
                                "resume-data": _vm.resumeData,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center align-items-center",
                      },
                      [_c("p", [_vm._v("Nenhum produto adicionado")])]
                    ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "sidebar-footer",
        fn: function () {
          return [
            _c("div", { staticClass: "cart-footer" }, [
              _c(
                "div",
                { staticClass: "cart-footer-actions" },
                [
                  _c("e-button", {
                    staticClass: "w-100",
                    attrs: {
                      variant: "secondary",
                      text: _vm.$t("Continuar compra"),
                      size: "lg",
                    },
                    on: {
                      click: function () {
                        return _vm.onHide()
                      },
                    },
                  }),
                  _c("e-button", {
                    staticClass: "w-100",
                    attrs: {
                      variant: "primary",
                      text: _vm.$t("Pagamento"),
                      size: "lg",
                    },
                    on: {
                      click: function () {
                        return _vm.onPayment()
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }