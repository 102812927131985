<template>
  <div
    class="group-item"
    :class="{ 'group-item-selected': selected }"
    @click="$emit('click', group)"
  >
    <div>
      <b-img
        class="group-img"
        fluid
        :src="group.image"
      />
    </div>

    <p
      class="mb-0"
      style="text-align: center; font-size: 0.8rem; font-weight: bold;"
    >
      {{ group.name }}
    </p>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import logoImg from '@/assets/images/logo/echope_small.png'

export default {
  components: { BImg },
  props: {
    group: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    groupImage() {
      return this.group.image || logoImg
    }
  }
}
</script>

<style lang="scss" scoped>
.group-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  border-radius: 5px;

  cursor: pointer;

  min-width: 100px;
  // height: 100px;

  transition: 200ms ease-in;

  .group-img {
    border-radius: 10px;
    border: 1px solid var(--gray);
    background-color: var(--white);
    width: 100%;
    height: 80px;
  }
}

.group-item-selected {
  background-color: var(--theme-color);
  color: var(--light);
}
</style>
