<template>
  <b-card class="product-card mb-0 position-relative">
    <div
      v-if="showRemove"
      class="position-absolute"
      style="top: 0; right: 0;"
    >
      <e-button-icon
        icon="XIcon"
        variant="danger"
        style="padding: 12px;"
        @click="onRemoveItem"
      />
    </div>
    <div class="card-container">
      <div class="d-flex justify-content-center">
        <b-img
          :src="item.imageSquare || logoImg"
          width="150"
          height="150"
          alt="Fluid image"
          class="rounded"
        />
      </div>

      <div>
        <p class="mb-0 h6 font-weight-bold">
          {{ item.name }}
        </p>
      </div>

      <div class="mt-auto">
        <p class="mb-0 font-weight-bold">
          {{ item.price | currency }}
        </p>
      </div>

      <div>
        <FormulateInput
          :id="`${id}_pId_${item.id}`"
          v-model="getItemQuantity"
          type="osk-number-with-buttons"
          :name="`${id}_pId_${item.id}`"
          input-disabled
          @oskInput="onOskInput"
          @leftClick="onMinusClick"
          @rightClick="onPlusClick"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import EButtonIcon from '@/views/components/EButtonIcon.vue'
import { BImg, BCard } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import logoImg from '@/assets/images/logo/echope_small.png'
import { debounce } from 'lodash'
import { payBoxSale } from '@/mixins'

export default {
  components: {
    BCard,
    BImg,
    EButtonIcon
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      default: 'itemCard'
    },

    readOnly: {
      type: Boolean,
      default: false
    },
    showRemove: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      quantityAux: 0,
      showLocalQuantity: false,
      logoImg,
    }
  },

  computed: {
    ...mapGetters('pages/pdv/payBox', ['getItems', 'getDictItemsQuantity']),

    getItemQuantity: {
      get() {
        let result = 0
        if (this.showLocalQuantity) {
          result = this.quantityAux
        } else {
          result = this.getQuantity()
        }

        return result
      },
      set() { }
    }
  },

  mounted() {
    this.reloadItemQuantity()
  },

  methods: {
    ...mapActions('pages/pdv/atm', {
      stAddItemFromAtm: 'addItemFromAtm',
      stRemoveItemFromAtm: 'removeItemFromAtm',
      stUpdateItemFromAtm: 'updateItemFromAtm',
    }),

    reloadItemQuantity() {
      this.quantityAux = this.getQuantity()
    },

    getItemCart() {
      const itemFound = this.getItems?.find(payBoxSale.methods.findProductInSaleItem(this.item))
      return itemFound
    },
    getQuantity() {
      // item.id pode ser kitId ou o skuId
      const saleProductKey = payBoxSale.methods.getSaleProductKey({
        kitId: (this.item.kit && this.item.id) || 0,
        promotionId: this.item.promotionId,
        skuId: (!this.item.kit && this.item.id)
      })
      const amount = this.getDictItemsQuantity[saleProductKey]
      const result = amount || 0

      if (!this.showLocalQuantity) this.quantityAux = result

      return result
    },

    // eslint-disable-next-line func-names
    onOskInput: debounce(function (val) {
      this.manageItem(val)
    }, 400),

    onMinusClick() {
      if ((this.quantityAux - 1) < 0) {
        return
      }

      this.showLocalQuantity = true
      this.quantityAux -= 1

      this.manageItem(this.quantityAux)
    },
    onPlusClick() {
      this.showLocalQuantity = true
      this.quantityAux += 1

      this.manageItem(this.quantityAux)
    },

    onRemoveItem() {
      const item = this.getItemCart()
      this.removeItem(item)
    },

    // eslint-disable-next-line func-names
    manageItem: debounce(async function (quantity) {
      try {
        const item = this.getItemCart()

        if (item && quantity <= 0) {
          await this.removeItem(item)
          return
        }

        if (this.getQuantity() > 0) {
          await this.updateItem(item, quantity)
        } else {
          await this.addItem(quantity)
        }
      } catch (error) {
        this.showAtmAlert({ error, icon: 'warning' })
      } finally {
        this.showLocalQuantity = false
      }
    }, 300),

    async addItem(quantity) {
      await this.stAddItemFromAtm({
        amount: quantity,
        ean: this.item.ean,
        skuId: this.item.id,
      })
    },
    async removeItem(item) {
      await this.stRemoveItemFromAtm(item)
    },
    async updateItem(item, quantity) {
      await this.stUpdateItemFromAtm({
        item,
        amount: quantity
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.product-card {
  position: inherit;
  height: 350px;

  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding-bottom: 0;
}

.product-card:nth-last-child(1) {
  margin-left: 0px;
  margin-right: auto;
}

.card-container {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
</style>
