<template>
  <div>
    <b-row
      v-if="catalog.length > 0"
      class="itens-container"
    >
      <b-col
        v-for="(item) in catalog"
        :key="`${item.kit ? 'kit' : 'product'}-${item.id}`"
        md="3"
        class="mb-2"
      >
        <item-card :item="item" />
      </b-col>

      <b-col
        v-if="catalogLoading"
        md="12"
        class="p-3 text-center"
      >
        Carregando mais produtos...
      </b-col>

      <b-col
        v-if="paging.allRowsLoaded"
        md="12"
        class="p-3 text-center"
      >
        Todos os nossos produtos já foram carregados
      </b-col>
    </b-row>
    <div
      v-else-if="catalogLoading"
      class="d-flex align-items-center justify-content-center"
    >
      <p>Buscando produtos...</p>
    </div>
    <div
      v-else
      class="d-flex align-items-center justify-content-center"
    >
      <p>Nenhum produto encontrado</p>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, } from 'bootstrap-vue'
import { mapState } from 'vuex'
import ItemCard from '../cards/ItemCard.vue'

export default {
  components: {
    ItemCard,
    BRow,
    BCol,
  },

  data() {
    return {

    }
  },

  computed: {
    ...mapState('pages/pdv/atm/catalog', ['catalog', 'catalogLoading', 'paging']),
  },

  methods: {

  }
}
</script>


<style lang="scss">
.itens-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
</style>
