var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.getModalRefName,
        title: _vm.$t("Informar documento"),
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
        "hide-footer": "",
        scrollable: "",
        centered: "",
        size: "sm",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _c(
        "FormulateForm",
        {
          ref: "formAtmModalCustomer",
          attrs: { name: "formAtmModalCustomer" },
          on: { submit: _vm.onConfirm },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "mt-1 d-flex justify-content-center mb-2" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "8" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "atm-tab-client-document",
                          mask: ["###.###.###-##", "##.###.###/####-##"],
                          name: "document",
                          type: "osk-text-mask",
                          label: _vm.$t("CPF ou CNPJ"),
                          placeholder: _vm.$t("CPF ou CNPJ"),
                          validation: "required|cpfCnpj",
                          "osk-type": "numeric",
                          "native-type": "number",
                        },
                        model: {
                          value: _vm.localForm.document,
                          callback: function ($$v) {
                            _vm.$set(_vm.localForm, "document", $$v)
                          },
                          expression: "localForm.document",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-end",
                      staticStyle: { gap: "10px" },
                      attrs: { md: "12" },
                    },
                    [
                      _c("e-button", {
                        attrs: {
                          variant: "outline-danger",
                          text: _vm.$t("Não desejo informar"),
                        },
                        on: { click: _vm.onCancel },
                      }),
                      _c("e-button", {
                        attrs: {
                          variant: "primary",
                          text: _vm.$t("Confirmar documento"),
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }