var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-card", { staticClass: "product-card mb-0 position-relative" }, [
    _vm.showRemove
      ? _c(
          "div",
          {
            staticClass: "position-absolute",
            staticStyle: { top: "0", right: "0" },
          },
          [
            _c("e-button-icon", {
              staticStyle: { padding: "12px" },
              attrs: { icon: "XIcon", variant: "danger" },
              on: { click: _vm.onRemoveItem },
            }),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "card-container" }, [
      _c(
        "div",
        { staticClass: "d-flex justify-content-center" },
        [
          _c("b-img", {
            staticClass: "rounded",
            attrs: {
              src: _vm.item.imageSquare || _vm.logoImg,
              width: "150",
              height: "150",
              alt: "Fluid image",
            },
          }),
        ],
        1
      ),
      _c("div", [
        _c("p", { staticClass: "mb-0 h6 font-weight-bold" }, [
          _vm._v(" " + _vm._s(_vm.item.name) + " "),
        ]),
      ]),
      _c("div", { staticClass: "mt-auto" }, [
        _c("p", { staticClass: "mb-0 font-weight-bold" }, [
          _vm._v(" " + _vm._s(_vm._f("currency")(_vm.item.price)) + " "),
        ]),
      ]),
      _c(
        "div",
        [
          _c("FormulateInput", {
            attrs: {
              id: _vm.id + "_pId_" + _vm.item.id,
              type: "osk-number-with-buttons",
              name: _vm.id + "_pId_" + _vm.item.id,
              "input-disabled": "",
            },
            on: {
              oskInput: _vm.onOskInput,
              leftClick: _vm.onMinusClick,
              rightClick: _vm.onPlusClick,
            },
            model: {
              value: _vm.getItemQuantity,
              callback: function ($$v) {
                _vm.getItemQuantity = $$v
              },
              expression: "getItemQuantity",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }