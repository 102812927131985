<template>
  <e-sidebar
    :show="showSidebar"
    :title="$t('Carrinho')"
    width="700px"
    @hidden="onHide"
  >
    <template #content>
      <div class="cart-container">
        <b-row
          v-if="getItems.length > 0"
          class="cart-itens-container"
        >
          <b-col md="12">
            <e-pay-box-sale-item-table
              class="bordered"
              :table-items="getItems"
              show-img
              :show-number="false"
              show-table-footer
              item-read-only
              prices-to-right
              :resume-data="resumeData"
            />
          </b-col>

          <!-- <b-col
            v-for="(product, index) in getProductsFromItems"
            :key="`cart_${product.id}_${index}`"
            md="6"
            class="mb-2"
          >
            <item-card
              :id="`cart_product-${product.id}`"
              :item="product"
              show-remove
            />
          </b-col> -->
        </b-row>
        <div
          v-else
          class="d-flex justify-content-center align-items-center"
        >
          <p>Nenhum produto adicionado</p>
        </div>
      </div>
    </template>

    <template #sidebar-footer>
      <div class="cart-footer">
        <div class="cart-footer-actions">
          <e-button
            variant="secondary"
            class="w-100"
            :text="$t('Continuar compra')"
            size="lg"
            @click="() => onHide()"
          />

          <e-button
            variant="primary"
            class="w-100"
            :text="$t('Pagamento')"
            size="lg"
            @click="() => onPayment()"
          />
        </div>
      </div>
    </template>
  </e-sidebar>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ESidebar from '@/views/components/ESidebar.vue'
import { atmScreenEnum } from '@/store/pages/pdv/atm'
import EButton from '@/views/components/EButton.vue'
import EPayBoxSaleItemTable from '@/views/components/tables/EPayBoxSaleItemTable.vue'
// import ItemCard from './cards/ItemCard.vue'

export default {
  components: {
    // ItemCard,
    EButton,
    BCol,
    BRow,
    ESidebar,
    EPayBoxSaleItemTable,
  },

  props: {},

  data: () => ({
    showSidebar: false,
  }),

  computed: {
    ...mapGetters('pages/pdv/atm/status', ['getIsCartOpen']),

    ...mapGetters('pages/pdv/payBox', [
      'getItems',
      'getTotalSale',
      'getTotalItemsQuantity'
    ]),

    resumeData() {
      return {
        total: this.getTotalSale,
        totalQuantity: this.getTotalItemsQuantity,
      }
    }

    // getProductsFromItems() {
    //   return this.getItems?.map(item => ({
    //     ...item.product,
    //     id: item.product.kit ? item.product.id : item.product.skuId,
    //     promotionId: item.promotionId,
    //     name: item.product.name,
    //     ean: item.product.ean,
    //     imageSquare: item.product.imageSquare,
    //     price: item.product.price,
    //   })) || []
    // }
  },

  watch: {
    getIsCartOpen(val) {
      if (val) {
        this.showSidebar = true
      } else {
        this.showSidebar = false
      }
    }
  },

  methods: {
    ...mapActions('pages/pdv/atm/status', {
      stSetIsCartOpen: 'setIsCartOpen',
    }),

    ...mapActions('pages/pdv/atm/status', {
      stSetAtmScreen: 'setAtmScreen',
    }),

    async onPayment() {
      this.stSetAtmScreen(atmScreenEnum.PAYMENT)
      this.onHide()
    },

    onHide() {
      this.stSetIsCartOpen(false)
    }
  },
}
</script>

<style lang="scss" scoped>
.cart-container {
  // padding: 5px;
  border-radius: 0.428rem;

  // width: 98vw;
  // scrollbar-width: none;

  // animation: scroll-shadow-inset linear;
  // animation-timeline: scroll(self inline);

  .cart-itens-container {
    display: flex;
    justify-content: flex-start;
    // gap: 5px;
  }
}

.cart-footer {
  .cart-footer-actions {
    display: flex;
    gap: 20px;
  }
}
</style>
