import { render, staticRenderFns } from "./AtmHeader.vue?vue&type=template&id=26b68b35&scoped=true&"
import script from "./AtmHeader.vue?vue&type=script&lang=js&"
export * from "./AtmHeader.vue?vue&type=script&lang=js&"
import style0 from "./AtmHeader.vue?vue&type=style&index=0&id=26b68b35&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26b68b35",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/echope/echope-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26b68b35')) {
      api.createRecord('26b68b35', component.options)
    } else {
      api.reload('26b68b35', component.options)
    }
    module.hot.accept("./AtmHeader.vue?vue&type=template&id=26b68b35&scoped=true&", function () {
      api.rerender('26b68b35', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/pdv/atm/components/AtmHeader.vue"
export default component.exports