var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "catalog-container" },
    [
      _vm.getAtmScreen === _vm.atmScreenEnum.CATALOG
        ? [
            _c("div", { staticClass: "atm-header" }, [_c("atm-header")], 1),
            _c(
              "div",
              {
                ref: "scrollContainer",
                staticClass: "products-container",
                attrs: { id: "atm-catalog-main-container" },
                on: { scroll: _vm.onCatalogScroll },
              },
              [
                _c("atm-catalog", {
                  on: {
                    scrollToTop: function () {
                      return _vm.onScrollToTop("atm-catalog-main-container")
                    },
                  },
                }),
                !_vm.isLoadedAllCatalog && !_vm.isLoadingCatalog
                  ? _c(
                      "div",
                      { staticClass: "px-5 w-100 flex justify-content-center" },
                      [
                        _c("e-button", {
                          staticClass: "w-100",
                          attrs: { text: "Carregar mais produtos" },
                          on: {
                            click: function () {
                              return _vm.stFetchAtmProductCatalog()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("atm-cart-sidebar"),
          ]
        : _vm._e(),
      _vm.getAtmScreen === _vm.atmScreenEnum.PAYMENT
        ? [
            _c(
              "section",
              { staticClass: "payment-container" },
              [_c("atm-payment")],
              1
            ),
          ]
        : _vm._e(),
      _c("footer", { staticClass: "footer-container" }, [_c("atm-footer")], 1),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }