<template>
  <div class="p-2 d-flex flex-column justify-content-center align-items-center">
    <b-row class="w-100">
      <b-col md="12">
        <b-card
          title="Resumo"
          class="w-100 h-auto"
        >
          <div style="overflow-y: auto; max-height: 600px;">
            <e-pay-box-sale-item-table
              class="bordered"
              :table-items="getItems"
              show-img
              item-read-only
              prices-to-right
              :show-number="false"
            />
          </div>

          <div class="mt-1 d-flex justify-content-end ">
            <p class="h3">
              Total: {{ getTotalSale | currency }}
            </p>
          </div>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card :title="$t('Formas de pagamento')">
          <b-row
            class="mb-1"
            style="row-gap: 10px"
          >
            <b-col
              v-for="item in paymentMethodsInAtm"
              :key="item.id"
              md="4"
            >
              <e-button-card
                :id="`atm_payment-method_btn-${item.id}`"
                icon-component="BIcon"
                :icon="getIconByPaymentMethod[item.method]"
                :text-title="item.name"
                @click="onShowModalValue(item)"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-if="hasCanceledTefPixPayment"
              md="12"
            >
              <b-alert
                show
                variant="danger"
                class="p-2"
              >
                {{ $t('PAY_BOX_SALE.TEF.ATM.INSTRUCTION_TEF_CANCEL_PIX') }}
              </b-alert>
            </b-col>

            <b-col md="12">
              <method-line
                v-for="(receipt, index) in paymentForm.receipts"
                :id="`atm_payment-method_item-${index}`"
                :key="index"
                :receipt="receipt"
                :value="receipt.value"
                :is-cancelled="receipt.canceled"
                :show-btn-canceled="showCancelBtn(receipt)"
                class="rounded"
                @click-delete="onDeleteMethodPayment(index)"
                @click-print="stPrintReceipt({ index })"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <modal-value
      ref="modalValue"
      :sale-uuid="currentSaleUuid"
      :balance-value="paymentForm.balance"
      :btn-confirm-busy="waitPeripheral"
      is-atm
      @click-confirm="onStartFinishFlow"
    />

    <modal-atm-customer
      id="payment"
      ref="modalAtmCustomerInPayment"
    />
  </div>
</template>

<script>
import EButtonCard from '@/views/components/EButtonCard.vue'
import EPayBoxSaleItemTable from '@/views/components/tables/EPayBoxSaleItemTable.vue'
import { BRow, BCol, BAlert, BCard, } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { paymentTypes, payBoxPayments, payBoxTypes, loadingOverlay } from '@/mixins'
import { isTefCard, isTefPix } from '@/store/utils/paybox/paybox-payments'
import { getInitialAtmCatalogFilters } from '@/store/pages/pdv/atm/atm-catalog'
import ModalValue from '../pay-box/components/tabs/components/ModalValue.vue'
import MethodLine from '../pay-box/components/tabs/components/MethodLine.vue'
import ModalAtmCustomer from './components/modals/ModalAtmCustomer.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    EButtonCard,
    BCard,
    EPayBoxSaleItemTable,
    ModalValue,
    MethodLine,
    ModalAtmCustomer,
  },

  mixins: [paymentTypes, payBoxTypes, payBoxPayments, loadingOverlay],

  data() {
    return {
      waitPeripheral: false
    }
  },

  computed: {
    ...mapState('pages/pdv', ['paymentMethodsInAtm']),

    ...mapState('pages/pdv/payBox', ['paymentForm', 'items']),
    ...mapGetters('pages/pdv/payBox', [
      'getItems',
      'getTotalSale',
      'currentSaleUuid',
      'hasCanceledTefPixPayment',
    ]),

    getIconByPaymentMethod() {
      return {
        None: 'currency-dollar',
        CreditCard: 'credit-card',
        DebitCard: 'credit-card',
        Pix: 'x-diamond-fill',
        Cash: 'cash',
      }
    },
  },

  methods: {
    ...mapActions('pages/pdv/atm', {
      stCleanAtmState: 'cleanState',
    }),
    ...mapActions('pages/pdv/atm/status', {
      stSetIsPaymentError: 'setIsPaymentError',
      stSetIsFinishError: 'setIsFinishError',
    }),
    ...mapActions('pages/pdv/atm/catalog', {
      stSetFiltersAndSearch: 'setFiltersAndSearch',
    }),

    ...mapActions('pages/pdv/payBox', {
      stAddReceipt: 'addReceipt',
      stRemoveReceipt: 'removeReceipt',
      stPrintReceipt: 'printReceipt',
      stValidatePayBoxActionIsBlocked: 'validatePayBoxActionIsBlocked',

      stCleanPayBoxState: 'cleanState',
      stFinishSale: 'finishSale',
      stSetClientForm: 'setClientForm',
    }),

    showCancelBtn(item) {
      if (item.id) return false
      return true
    },

    async onShowModalValue(paymentMethod) {
      const isBlocked = await this.stValidatePayBoxActionIsBlocked({
        action: this.uiPayBoxActionsEnum.PAYMENT_ADD,
      })
      if (isBlocked) {
        this.showWarning({ message: this.$t('Ação bloqueada no momento.') })
        return
      }

      const error = { message: '' }

      if (this.getTotalSale <= 0) {
        error.message = 'Total da compra está zerado'
      } else if (this.paymentForm.balance === 0) {
        error.message = 'Saldo a pagar está zerado'
      }

      if (error.message) {
        this.showWarning({ message: error.message })
        return
      }

      try {
        await this.$refs.modalValue.payOnAtm(paymentMethod)
      } catch {
        this.stSetIsPaymentError(true)
      }
    },

    async onStartFinishFlow(value) {
      await this.onAddMethodPayment(value)

      await this.onFinish()
    },

    async onAddMethodPayment(value) {
      try {
        this.waitPeripheral = true

        await this.stAddReceipt(value)
        await this.stPrintReceipt({ index: -1 }) // print last

      } catch (error) {
        this.showError({ error, message: 'Erro ao adicionar pagamento' })
      } finally {
        this.waitPeripheral = false
      }
    },

    async onDeleteMethodPayment(index) {
      try {
        // const isBlocked = await this.stValidatePayBoxActionIsBlocked({
        //   action: this.uiPayBoxActionsEnum.PAYMENT_REMOVE,
        // })
        // if (isBlocked) {
        //   this.showWarning({ message: this.$t('Ação bloqueada no momento.') })
        //   return
        // }

        const confirmOptions = {
          text: this.$t('Deseja realmente cancelar este recebimento ?'),
          focusCancel: true,
        }

        const receipt = this.paymentForm.receipts[index]

        if (isTefPix(receipt)) {
          confirmOptions.text = this.$t('PAY_BOX_SALE.TEF.ATM.CONFIRMATION_TEF_CANCEL_PIX')
        }

        const confirm = await this.confirm(confirmOptions)
        if (confirm) {
          const tefPaymentCancelation = await this.cancelTefPayment(receipt)
          if (tefPaymentCancelation || !isTefCard(receipt)) {
            this.stRemoveReceipt({ index, tefPaymentCancelation })
          }
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    async onFinish() {
      const messages = {
        // confirm: { title: this.$t('Finalizar compra?'), text: ' ', icon: 'question' },
        overlay: this.$t('Finalizando compra'),
        successToast: { title: this.$t('Compra finalizada!') },
      }

      // const confirm = await this.confirm(messages.confirm)

      // if (!confirm) return

      const localCustomerForm = await this.$refs.modalAtmCustomerInPayment.showModal()
      this.stSetClientForm({ document: localCustomerForm?.document })

      try {
        // const coldOption =
        //   this.coldOption ??
        //   (this.hasColdOptionProducts && !this.isKioskStore
        //     ? await this.$refs.modalColdOption.showModal()
        //     : null) ??
        //   this.coldOptionEnum.NATURAL

        this.showLoadingOverlay(messages.overlay)
        const resultFinishSale = await this.stFinishSale()
        await this.onCleanSale()

        await this.showAtmAlert({
          title: messages.successToast.title,
          text: resultFinishSale.displayMessage,
          icon: 'success',
          confirmButtonText: this.$t('Ok'),
        })
      } catch (error) {
        this.stSetIsFinishError(true)

        await this.showAtmAlert({
          error,
          title: 'Não foi possível concluir a compra',
          icon: 'error',
          confirmButtonText: this.$t('Voltar'),
          timer: null
        })
        // const data = error.response?.data
        // if (data?.Request && Array.isArray(data?.Request)) {
        //   this.showLoadingOverlay(this.$t('PAY_BOX_SALE.CLOSING_ALREADY_FINISHED_OVERLAY'))
        //   if (
        //     data.Request.includes('SALE_ORDER.FINISH_ERROR.SALE_ALREADY_COMPLETED_OR_CANCELED')
        //   ) {
        //     await this.onCancel({ forceExit: true })
        //   }
        // }
      } finally {
        this.isFinishingSale = false
        this.hideLoadingOverlay()
      }
    },

    async onCleanSale() {
      await this.stCleanPayBoxState()
      await this.stCleanAtmState()
      this.stSetFiltersAndSearch(getInitialAtmCatalogFilters())
      this.limitExceeded = 0.0
    },
  },
}
</script>

<style scoped>
.dots-line {
  width: 100%;
  border-bottom: 1px dotted black;
  margin-bottom: 5px;
}
</style>
