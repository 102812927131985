<template>
  <div class="p-2">
    <b-row
      class="mb-1 py-1 position-sticky bg-light d-flex justify-content-between"
      style="top: 0px; z-index: 1"
    >
      <b-col md="12">
        <div class="group-container">
          <template v-if="getGroups.length > 0">
            <group-item
              v-for="(group, index) in getGroups"
              :key="`${group.id}_${index}`"
              :group="group"
              :selected="filters.groupId === group.id"
              @click="onSelectGroup"
            />
          </template>
          <template v-else>
            Nenhum grupo encontrado para filtrar
          </template>
        </div>
      </b-col>
      <b-col
        md="12"
        class="mt-2"
      >
        <FormulateInput
          v-model="productSearch"
          type="osk-text"
          placeholder="Pesquisar Produto"
          name="productSearch"
          osk-type="mobile"
          @input="onSearchProduct"
        />
      </b-col>
    </b-row>

    <atm-catalog-product-list />
  </div>
</template>

<script>
import { BRow, BCol, } from 'bootstrap-vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { debounce } from 'lodash'
import AtmCatalogProductList from './lists/AtmCatalogProductList.vue'
import GroupItem from './cards/GroupItem.vue'

export default {
  components: {
    AtmCatalogProductList,
    GroupItem,
    BRow,
    BCol,
  },

  props: {},

  data: () => ({
    catalogForm: {},
    productSearch: '',
  }),

  computed: {
    ...mapState('pages/pdv/atm/catalog', ['filters']),
    ...mapGetters('pages/pdv/atm/catalog', ['getGroups']),
  },

  watch: {
    // eslint-disable-next-line func-names
    productSearch: debounce(function () {
      this.$emit('scrollToTop')
    }, 300),

    filters(val) {
      if (val.name !== this.productSearch) {
        this.productSearch = val.name
      }
    }
  },

  mounted() {
    this.productSearch = this.filters.name
  },

  methods: {
    ...mapActions('pages/pdv/atm/catalog', {
      stSetFiltersAndSearch: 'setFiltersAndSearch'
    }),

    onSelectGroup(group) {
      if (this.filters.groupId === group.id) {
        this.stSetFiltersAndSearch({ groupId: null })
      }
      else {
        this.stSetFiltersAndSearch({ groupId: group.id })
      }
    },

    // eslint-disable-next-line func-names
    onSearchProduct: debounce(function () {
      this.stSetFiltersAndSearch({ name: this.productSearch })
    }, 1000)
  },
}
</script>

<style lang="scss" scoped>
.summary-card {
  width: 500px;
}

.group-container {
  display: flex;
  gap: 5px;

  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

}

.itens-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
</style>
